.containerload1{
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    background-color:gainsboro;
}
.mainload1-box{
    width: 100px;
    height: 100px;
    /* background-color: gray; */
    position: relative;
}
.mainload1-box span{
   position:absolute;
    width: 40%;
    height: 40%;
    background-color:blue; 
    animation: load 2s linear infinite;
} 
.mainload1-box span:nth-child(2){
    animation-delay: 0.5s;
    background-color: red;
}
.mainload1-box span:nth-child(3){
    animation-delay: 1s;
    background-color: green;
}
.mainload1-box span:nth-child(4){
    animation-delay: 1.5s;
    background-color: brown;
}
@keyframes load {
    0%{
        top: 0;
        left: 0;
    }
        12.5%{ 
            top: 0;
            left:50%;
        }
        25%{
            top:0;
            left:50%;
        }
    37.5%{
        top:50%;
        left:50%;
    }
    50%{
        top:50%;
        left:50%;
    }
        62.5%{
            left:0;
            top:50%;
        }
        75%{
            left:0;
            top:50%;
        }
    87.5%{
        top: 0;
        left: 0;
    }
    0%{
        top: 0;
        left: 0;
    }
}


/* //loader2 */



.mainload2{
    display: grid;
    place-items: center;
    background:lightblue;
    height: 100vh;
    width: 100vw;
        }
.loader{
    width: 6rem;
    height: 6rem;
    border: 1rem solid white;
    border-radius: 50%;
    border-top:1rem solid rgb(152, 90, 223);
    animation: loader 1s linear infinite;
}
@keyframes loader{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}