
@media(min-width: 800px){ 
	::-webkit-scrollbar{
		background:linear-gradient(90deg ,rgb(255, 132, 0)); 
		width: 8px;
	} 
	::-webkit-scrollbar-button{
	background-color: rgb(156, 87, 220);
	height:77px;
	} 
	::-webkit-scrollbar-thumb{
		background-color:rgb(166,1,255);
		border-radius: 40px;
	} 
	::-moz-scrollbar-thumb{
		background-color:rgb(255, 153, 0);
	}
}

*{
	scroll-behavior: smooth;
}

body{
	padding:0;
	margin:0;
	background:#fff;
	position:relative;
	font-family: 'Nunito', sans-serif; 
}
body a{
    transition:0.5s all;
	-webkit-transition:0.5s all;
	-moz-transition:0.5s all;
	-o-transition:0.5s all;
	-ms-transition:0.5s all;
}
a:hover{
	text-decoration:none;
} 
h1,h2,h3,h4,h5,h6{
	margin:0;	
	font-family: 'Exo 2', sans-serif; 
	color:blue;
}	
h1{
	text-shadow: 0px 0px 1px rgb(128, 128, 128), 2px 3px 2px rgb(69, 37, 252);
	color:white !important;
} 
p{
	margin:0;
	font-family: 'Nunito', sans-serif;
	letter-spacing:1px;
	color:#999;
	line-height:1.8em;
	font-size:1em;
}
ul{
	margin:0;
	padding:0;
}
label{
	margin:0;
}
/*-- nav --*/
.main_section_agile {
    background: #002147;
}
.navbar-default {
    background: none;
    border: none;
}
.navbar {
    margin-bottom: 0;
	width: 100%;
}
.navbar-collapse {
    padding: 0;
}
.navbar-right {
    margin-right: 0;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color:#ffb71c;
    background:none;
}
.navbar-default .navbar-nav > .active > a:before{
	background: #ffb71c;
	transform: translateX(0);
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-o-transform: translateX(0);
	-ms-transform: translateX(0);
}
.navbar-default .navbar-nav > li > a {
    font-weight: 700;
    letter-spacing: 1px;
}
.navbar-nav > li > a {
    margin: 0 1.5em;
    padding: 0 0 0.5em;
    text-transform: uppercase;
}
.navbar-default .navbar-nav > li > a:hover{
    color:#ffb71c;
}
.navbar-default .navbar-nav > li > a:focus {
    color: #ffb71c;
	outline:none;
}
nav.navbar.navbar-default {
    background: none;
	  padding: 1.7em 0 0.2em 0;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    color: #262c38;
    background: none;
}
.navbar-default .navbar-nav > li > a {
    color:#fff;
}
.navbar-default .navbar-nav  > .active.open > a:focus {
	background:transparent;
	color: #fff;
}
a.navbar-brand {
    color: #fff!important;
    padding: 0;
    font-size: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
}
a.navbar-brand i{
	color:#ffb71c;
}
.navbar-right {
    float: left !important;
}
div#bs-example-navbar-collapse-1 {
    margin-top: .3em;
    padding-left: 4em;
}
   
.mybtn{
	width: auto;
	height: 40px;
	background-color:transparent;
	border:1px solid rgb(47, 0, 255);
	color:red;
	display: grid;
	place-items: center; 
	text-decoration: none;
	color:blue;
	position: relative;
	overflow: hidden; 
	border-radius: 3px;
	font-weight: bold; 
	transition: all 0.6s;
	padding: 0 20px;
	outline: none;
  }
  .mybtn:hover{ 
	color:orange; 
	transform: scale(1.1);
  }
  .mybtn::before{
	content: "";
	position:absolute;
	width: 100%;
	height: 30%;
	top:0;
	background-color: blue;
	transform: translateX(-47%) rotate(140deg);
	transition: all 0.6s;
  }
  .mybtn:hover::before{
	transform: translateX(70%) rotate(135deg);
  }   
  .main{
	/* background-image:linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('./components/bg.jpg'); */
	position: absolute;
	top:0; 
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100vh; 
	display: flex;
	flex-direction: column; 
	justify-content: center;  
	filter: contrast(10px);
	color: white;
	padding-left: 130px; 
  }  
  @media screen and (max-width: 800px) {
	.main{ 
		background-image:linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('./components/bg.jpg');
	position: relative;
	}
	#myCarousel{
		display: none;
	} 
  }

  .main h1{ 
	color: white;   
	font-size: 40px;
	text-shadow: 0px 0px 5px blue, 0px 0px 5px violet, 0px 0px 5px blue;
  } 
  .main p{
	max-width: 750px;  
	color:#fff;
	font-weight: bold;
	text-shadow: 0px 0px 5px blue, 0px 0px 5px violet, 0px 0px 5px blue;
	margin: 20px 20px 20px 0px;
  }
  .social-icons a{ 

	font-size: 30px;
	margin: 40px 10px  ; 
	background: #ffffff;
	outline: none;
	border-radius: 50%;
	padding:10px 12px; 
	color:black; 
	text-decoration: none;   
	box-shadow: inset -2px -2px 5px rgba(0, 0, 0, 0.72),inset 3px 3px 5px rgb(255, 255, 255),inset 3px 3px 5px white,inset 3px 3px 10px white;
	transition:0.3s; 
	
  } 
  .social-icons a:hover{
	transform: scale(1.2);
  }

  .front-btn{
	box-shadow: 0px 0px 5px blue, 0px 0px 5px violet, 0px 0px 5px blue;
  }


  @media screen and (max-width: 600px) { 
	  .main p{
	font-size: 15px;
}
  }

  @media screen and (max-width: 800px) {
	.main{padding-left: 10px;}
  }

.about_img h5{
	display: flex;
	flex-direction: column;
}

.about_img h5 span:first-child{
	font-size: 3rem;
	line-height: 30px;
}

.about_img h5 span:nth-child(2) {
	font-size: 2rem;
	color: orange;
} 

 
 
/*-- bootstrap-pop-up --*/
.close {
    opacity: 1;
}
.modal-header {
	    border-bottom: none!important;
}
.modal-body p {
    color: #383535;
    text-align: left;
        padding: 2em 1em 1em;
    margin: 0 !important;
    line-height: 2em;
}
.modal-body p i {
    display: block;
    margin: 2em 0 0;
    color: #0ac876;
}
.modal-content {
    border-radius: 0;
}
.modal-body h5 {
    font-weight: 700;
    margin: 0 0 1em 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.4em;
    color: #262c38;
} 
/*-- about --*/
.about-top {
    padding: 5em 0 10em;
}
h3.title {
    font-size: 3.5em;
    color: #002147;
    text-align: center;
    letter-spacing: 1px;
	position:relative;
}
.header p {
    font-size: 10px !important;
    color: #ffb71c;
    position: relative;
    margin: .5em 0 1em !important;
    text-align: center;
}
.header p:before, .header p:after {
    content: '';
    background: #676666;
    height: 2px;
    width: 5%;
    position: absolute;
    top: 46%;
}
.header p:before {
    left: 41%;
}
.header p:after {
    right: 41%;
}  
 
.modal-body img {
    width: 100%;
}
.modal-body p {
    margin: 2em 0;
}
.modal-header {
    border-bottom: none !important;
}
/*-- //Scocial-Icons --*/
/*-- //about --*/
 

/*-- services / facility --*/  
.facility , .sub-facility{
	border-radius: 5px;
	
}
.sub-facility {
	margin: 10px; 
	background:linear-gradient(145deg,rgba(0, 0, 0, 0.193),rgba(0, 0, 0, 0.459)),url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKkBXQpzeRAzYYybj5Z_enXuKbyPQfjYYR8A&usqp=CAU'); 
	background-repeat: no-repeat, repeat;
	background-size: cover;
}
.sub-facility:hover{
	background:linear-gradient(145deg,rgba(0, 0, 0, 0.815),rgba(0, 0, 0, 0.459)),url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKkBXQpzeRAzYYybj5Z_enXuKbyPQfjYYR8A&usqp=CAU'); 
	background-repeat: no-repeat, repeat;
	background-size: cover;
}
.sub-facility h2{ 
	color: white;
}
 .Facility-row .facility .sub-facility{
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.487),3px 3px 6px rgba(0, 0, 0, 0.419);
	padding: 7px;
	transition: 0.5s;

 } 
 .facility-p{
	min-height: 18rem !important;
	display: flex;
	align-items: center;
	justify-content: center; 
	color: white;
 }

 .sub-facility:hover{
	 transform: scale(1.05);
 }
 

/*-- services/facility end --*/  

 
/*-- team --*/ 
.team-grid.grid-2-team {
    margin-top: 4em;
}
.team-w3l {
	background:-webkit-linear-gradient(#002147,rgba(0, 33, 71, 0.69));
	background:-moz-linear-gradient(#002147,rgba(0, 33, 71, 0.69));
	background:-ms-linear-gradient(#002147,rgba(0, 33, 71, 0.69));
    background: linear-gradient(#002147,rgba(0, 33, 71, 0.69));
}
.team-w3l h3.title {
    color: #fff;
}
.about_img {
    position: relative;
    overflow: hidden;
    width: 100%;
}
.about-poleft img {
    width: 400px;
    height: 400px;
}
.about_img h5 {
    position: absolute;
    line-height: 2.4;
    bottom: 0%;
	height: 70px;
    left: 0;
    opacity: 1;
    width: 100%;
    color: #000;
    background: #fff;
    -ms-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transition: 0.35s all ease;
    transition: 0.35s all ease;
    text-align: center;
    font-size: 2em;
}
.about_img img + h5 {
    margin-top: 0;
	margin-bottom:0;
}
.about_opa {
    color: #fff;
    padding: 50px 50px 0;
    opacity: 0;
    background: rgba(51, 51, 51, 0.8);
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.35s all ease;
    transition: 0.35s all ease;
}
.about_img:hover .about_opa {
    opacity: 1;
    top: 70px;
}
.about_img:hover h5 {
    top: 0;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: #ffb71c;
    color: #fff;
}
.about_opa p {
    line-height: 2.2em;
    text-align: center;
    font-size: 1.4em;
    margin-bottom: 30px;
    color: #e4e0e0;
}
/*-- team-contact --*/
 
/*-- //team --*/ 
/*-- Gallery Section --*/
#portfolio p {
  line-height: 1.8em;
  margin: 25px 0 0 0;
  font-size:1.1em;
  color: #ffffff;
  font-weight: 300;
}
.gallery-section h3{
   margin: 0;
  color: #ffffff;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 18px;
}
.gallery-grid {
  padding: 0;
}
.gallery1 {
position:relative;
}
.gallery1 .textbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: rgba(15, 36, 83, 0.62);
}
.gallery1:hover .textbox {
	-webkit-transform: scale(1);
	transform: scale(1);
}
.gallery-grid img{
		width:100%;
}

.textbox {
-webkit-transition: all 0.7s ease;
transition: all 0.7s ease;
text-align:center;
}
.textbox h4 {
    font-size: 2em;
    color: #FFF;
    margin-top: 3.5em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
}
.textbox p {
     font-size: 26px !important;
    color: #fff;
    margin: 6px 0 0 0!important;
    padding: 3px 0;
}
.gallery-grids {
  margin-top: 50px;
}
/*-- //Gallery Section --*/
/*-- contact --*/
 
.agile_contact_grid {
    background: #eee;
    width: 32%;
	padding: 1em 0 2em;
	box-shadow: 0px 2px 1px rgba(28, 28, 29, 0.42);
}
.agile_contact_grid:nth-child(2) {
    margin: 0 1em;
}
 
.contact-grid1 textarea {
    height: 140px;
    resize: none;
    width: 98.5%;
}
.contact-top1 label {
    font-size: 1.1em;
    font-weight: 500;
    color: #fff;
    text-align: left;
    display: inherit;
	margin-bottom: 0.5em;
}
.contact-top1 {
    background: #ffb71c;
    padding: 3em 3em 4em;
    margin-top: 3em;
} 
#map { 
    height: 524px; 
	width: 90%;
}
/*-- footer --*/

/* .footer{


} */


 
.footer_grid_left {
    padding: 4em 0;
}
.footer_grid_left1 h4 {
    font-size: 1.4em;
    font-weight: 600;
    color: #002147;
    text-transform: uppercase;
    position: relative;
    padding-bottom: .5em;
    margin-bottom: 1.5em;
    letter-spacing: 1px; 
}
.footer_grid_left1 h4:after{	
	content: '';
    background: #ffb71c;
    height: 2px;
    width: 20%;
    position: absolute;
    bottom: 0%;
    left: 0%; 
}
.footer_grid_left1 p{
	color:#999;
	line-height:2em; 
}
.footer_grid_left1 ul li {
    list-style-type: none;
    color: #ffb71c;
    margin-bottom: 1em;
    letter-spacing: 1px; 
}
.footer_grid_left1 ul li i{
	padding-right:1em; 
}
.footer_grid_left1 ul li:last-child,.footer_grid_right1 ul li:last-child{
	margin:0;
}
.footer_grid_left1 ul li a,.footer_grid_right1 ul li a{
	color:#212121;
	text-decoration:none; 
}
.footer_grid_left1 ul li a:hover{
	color:#ffb71c;
}
.footer_grid_right1 ul li{
	list-style-type:none;
	color:#212121;
	margin-bottom:1em;
	font-weight:600;
}
.footer_grid_right1 ul li i {
    padding-right: 1em;
    color: #ffb71c;
}
.footer_grid_right1 ul li a:hover{
	color:#ffb71c;
}
.footer_grid_right2_w3{
	float:left;
	width:33.33%;
}
.footer_grid_right_main1{
	text-align:center;
	padding:1em;
}
.agileits_footer_social li{
	display:inline-block !important;
}
.copy_right {
    padding: 1.5em 0;
    text-align: center;
	background:-webkit-linear-gradient(#ffd985,#e69f06);
	background:-moz-linear-gradient(#ffd985,#e69f06);
	background:-ms-linear-gradient(#ffd985,#e69f06);
    background: linear-gradient(to right bottom,#ffd985,#e69f06);
	background: rgb(0, 110, 255) !important;
}
.copy_right p {
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1.1em;
}
.copy_right p a{
	color:#002147;
	text-decoration:none;
}
.copy_right p a:hover{
	color:#fff;
}
/*-- //footer --*/
/*-- to-top --*/
#toTop {
    display: none;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 2%;
    overflow: hidden;
    z-index: 999;
    width: 42px;
    height: 32px;
    border: none;
    text-indent: 100%;
    /* background: url(/images/up2.png) no-repeat 0px 0px; */
}
#toTopHover {
    width: 64px;
    height: 64px;
	display: block;
	overflow: hidden;
	float: right;
	opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0);
}
/*-- //to-top --*/ 
@media(min-width: 995px){
	.footer{
	    background: linear-gradient(150deg, rgba(0, 0, 255, 0.842) , rgb(124, 0, 182));
	}
		.footer_grid_left1 h4,.footer_grid_left1 ul li ,.footer_grid_left1 ul li a,.footer_grid_left1 p{
			color:white;
		} 
}
/*--responsive--*/

@media(max-width: 1680px){
	.w3l_about_bottom_right {
		padding: 3.5em 3.5em 5.5em;
	}
}
@media(max-width: 1600px){
	.w3l_about_bottom_right {
		padding: 3.5em 3.5em 3em;
	}
}
@media(max-width: 1440px){
	.navbar-nav > li > a {
		margin: 0 1em;
	}
	.w3l_about_bottom_right {
		padding: 3em;
	}
	.header-w3l h4 {
		padding: .8em 0 2em;
	}  
	div#bs-example-navbar-collapse-1 {
		padding-left: 3em;
	}
	
}
@media(max-width: 1366px){
	.navbar-default .navbar-nav > li > a {
		letter-spacing: 0px;
	}
	.header-w3l h2 {
		font-size: 2.6em;
	}
	.header-w3l h4 {
		font-size: 1.4em;
	}  
}
@media(max-width: 1280px){
	.agileits_banner_nav {
		width: 94%;
	}
	.w3l_about_bottom_right {
		padding: 2em;
	} 
	.about-top {
		padding: 4em 0 10em;
	}
	.header p {
		margin: 0px !important;
	} 
	#map {
		height: 480px;
		width: 100%;
	}
	div#bs-example-navbar-collapse-1 {
		padding-left: 1em;
	}
	
}
@media(max-width: 1080px){
	a.navbar-brand {
		font-size: 0.8em;
	}
	div#bs-example-navbar-collapse-1 {
		margin: 4em 0 2em;
	}
	.header_right {
		width: 25%;
	}
	.navbar-default .navbar-nav > li > a {
		letter-spacing: 1px;
	}
	div#bs-example-navbar-collapse-1 {
		margin: 1.5em 0 1.5em;
	}
	.header-w3l h2 {
		font-size: 2.4em;
	}
	.header-w3l h4 {
		padding: .5em 0 1.2em;
	}
	li.text {
		font-size: .95em;
	} 
	.w3l_about_bottom_right {
		padding: 1.5em 2em 1.5em;
	}
	.about-top {
		padding: 3em 0 8em;
	} 
	 
 
 
	h3.title {
		font-size: 3.2em;
		letter-spacing: 0px;
	} 
	span.grid-ser {
		margin: .5em 0 0 !important;
	}
	.textbox h4 {
		margin-top: 2em;
		letter-spacing: 2px;
	}
	.about_opa {
		padding: 45px 40px 0;
	} 
	.navbar-right {
		float: right !important;
	}
}
@media(max-width: 1050px){
	.header-w3l h2 {
		font-size: 2.3em;
		letter-spacing: 0px;
	}
	.header-w3l h4 {
		font-size: 1.3em;
	}
	.w3l_about_bottom_right {
		padding: 1.2em 2em 1.5em;
	}
}
@media(max-width: 1024px){
	.header-w3l h2 {
		font-size: 2.1em;
	} 
	.w3l_about_bottom_right {
		padding: 1em 2em 1.3em;
	}
}
@media(max-width: 991px){
	.header_right {
		width: 30%;
	} 
	.w3l_about_bottom_right {
		padding: 3em;
	}
	.header-w3l h2 {
		font-size: 2.5em;
	}
	.header-w3l h4 {
		font-size: 1.5em;
		padding: .8em 0 2em;
	} 
	.header p:before, .header p:after {
		width: 9%;
	}
	.header p:before {
		left: 36%;
	}
	.header p:after {
		right: 37%;
	} 
 
	.about-top {
		padding: 3em 0 4em;
	} 
	.about_img h5 {
		line-height: 2;
		font-size: 1.3em;
		height: 42px;
	}
	.about_img:hover .about_opa {
		top: 42px;
	}
	.about_opa {
		padding: 25px 2px 0;
	}
	.about_opa p {
		line-height: 2em;
		font-size: 1.2em;
		margin-bottom: 25px;
	}
	.contact-grid1 textarea {
		width: 97%;
	}
	.contact-top1 {
		padding: 3em 2em 3em;
	} 
	.footer_grid_right1 ul li i {
		padding-right: .3em;
	}
	.footer_grid_left1 ul li {
		letter-spacing: 0px;
	}
}
@media(max-width: 900px){

}
@media(max-width: 800px){
	#map {
		height: 400px;
		width: 100%;
	}
	div#bs-example-navbar-collapse-1 {
		margin: .5em 0 1em;
	} 
	.textbox h4 {
		letter-spacing: 1px;
		font-size: 1.5em;
	}
	h3.title {
		font-size: 3em;
	}
	.contact-grid1 textarea {
		width: 97.5%;
	}
}
@media(max-width: 768px){

}
@media(max-width: 767px){
	.navbar-brand {
		font-size: .8em;
	}
	.navbar-toggle {
		margin: 0.5em 0 0;
	}
	.navbar-default .navbar-toggle {
		border: none;
		background: #fff;
		border-radius: 0;
		margin: 0;
	}
	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
		position: absolute;
		width: 100%;
		z-index: 999;
		background:rgba(0, 0, 0, 0.83);
		padding:0em 0;
		margin-top: 2em;
	}
 
	.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
		background:#ffb71c;
	}
	.navbar-default .navbar-toggle .icon-bar {
		background-color: #002147;
	}
	.agileits_banner_nav {
		padding: 0.5em 1em;
	}
	.navbar-right {
		width: 100%;
	}
	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
		border: none;
	}
	.navbar-nav {
		margin: 0;
		text-align:center;
	}
	.navbar-nav > li > a {
		padding: 5px 25px;
		font-size: 13px;
		display: inline-block;
	}
	.menu--iris .menu__item {
		margin: 6px 0;
	}
	.navbar-nav .open .dropdown-menu {
		background-color: #0ac876;
		padding:10px 0;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #fff;
	}
	.w3layouts-banner-top, .w3layouts-banner-top1, .w3layouts-banner-top2 {
		min-height: 450px;
	}
	.agileits-banner-info p {
		font-size: 0.9em;
		letter-spacing: 9px;
	}
	.agileits-banner-info {
		padding: 10em 0 0em;
	}
	.banner-bottom h3 {
		font-size: 1.5em;
	}
	.agile-post-img a ul {
		padding:7em 1em 0 2em;
	}
	.agile-post-img a {
		min-height: 350px;
	}
	.agile-post-img a ul li {
		font-size: 0.8em;
		padding: .5em 0em;
		width: 17%;
	}
	.agile-post-info ul li:nth-child(2) {
		margin: 0 0 0 35em;
	}
	ul.nav.navbar-nav {
		margin: 0em 0 0em 0;
	}
	
	nav.navbar.navbar-default{
		background:none;
		padding:1em 0 0.2em 0;
	}
	ul.nav.navbar-nav {
		margin: 1em 0;
	}
	ul.agile_forms {
		margin-bottom: 1em;
	}
	.agileits_event_grid1 h5 a {
		font-size: 1.2em;
		letter-spacing: 2px;
	}
	div#bs-example-navbar-collapse-1 {
		margin: 0;
	}
	.navbar-nav > li > a {
		margin: .4em 0;
	}
}
@media(max-width: 736px){ 
	.header_right {
		width: 34%;
	}  
	.agile_contact_grid {
		width: 31%;
	}
	.footer_grid_left1 {
		padding: 0 .5em;
	}
}
@media(max-width: 667px){
	.header_right {
		width: 38%;
	} 
	h3.title {
		font-size: 2.6em;
	}  
	.textbox h4 {
		margin-top: 1.2em;
	}
	.fb_icons2 li a {
		width: 26px;
		height: 26px;
		font-size: .8em;
		line-height: 2.2;
	}
	.team-grid.grid-2-team {
		margin-top: 2.5em;
	}
	 
	.footer_grid_left1{
		width: 100%;
	}
	.footer_grid_left1.footer_grid_right1 {
		float: right;
		width: 50%;
	}
	.footer_grid_left1.a3 {
		float: left;
		width: 50%;
	}
	.footer_grid_left1:nth-child(2) {
		margin: 2em 0;
	}
	.footer_grid_left1 h4 {
		font-size: 1.3em;
		margin-bottom: 1em;
	}
	.footer_grid_left1 ul li {
		margin-bottom: .8em;
	}
	.footer_grid_right1 ul li i {
		padding-right: 1em;
	}
	.copy_right p {
		font-size: 1em;
	}
	.footer_grid_left {
		padding: 3em 0;
	}
	.footer_grid_left1 h4:after {
		width: 10%;
	}	
	.form {
		padding: 4em 0 0;
	}
}
@media(max-width: 640px){
	.header-w3l h2 {
		font-size: 2.3em;
	}
	.header-w3l h4 {
		font-size: 1.4em;
	} 
	.about_opa p {
		font-size: 1em;
		margin-bottom: 14px;
	}
	.fb_icons2 li a {
		width: 25px;
		height: 25px;
	}
	.contact-top1 {
		margin-top: 2em;
	}
	.form {
		padding: 3em 0 0;
	}
}
@media(max-width: 600px){
	.header_right {
		width: 43%;
	} 
 
	.about-top {
		padding: 3em 0 3em;
	}
	 
	.header p {
		margin: 0px !important;
	} 
	.contact-top1 {
		padding: 2.5em 1em 3em;
	}
	/* #map {
		height: 330px;
		width: 100%;
	} */
	.fb_icons2 li a {
		width: 22px;
		height: 22px;
		font-size: .7em;
	}
	
}
@media(max-width: 568px){
	.w3l_about_bottom_right {
		padding: 3em 2em;
	}
	.gallery-grid.gallery1 {
		width: 50%;
	}
	.textbox h4 {
		margin-top: 3em;
	}
	.about-poleft {
		padding: 0 .5em;
	} 
	p {
		letter-spacing: 0px;
	}
}
@media(max-width: 480px){
	ul.agile_forms li a {
		letter-spacing: 0px;
	} 
	ul.agile_forms li a {
		font-size: .85em;
	}
	.header_right {
		width: 47%;
	} 
	.w3l_about_bottom_right {
		padding: 2em 1.5em;
	}
	.header-w3l h2 {
		font-size: 2em;
	}
	.header-w3l h4 {
		font-size: 1.3em;
	} 
	h3.title {
		font-size: 2.4em;
	}
	.header p:before {
		left: 34%;
	}
	.header p:after {
		right: 34%;
	}  
	  
	.about-poleft {
		width: 100%;
		padding: 0 1em;
	}
	.about-poleft:nth-child(2) {
		margin:3em 0;
	}
	.about_img h5 {
		line-height: 2.4;
		height: 70px;
		font-size: 2em;
	}
	.about_opa p {
		line-height: 2.2em;
		font-size: 1.4em;
		margin-bottom: 30px;
	} 
	.about_opa {
		padding: 75px 50px 0;
	}
	.about_img:hover .about_opa {
		top: 69px;
	}
	.agile_contact_grid {
		width: 100%;
	}
	.agile_contact_grid:nth-child(2) {
		margin: 2em 0;
	} 
	.copy_right p {
		line-height: 2;
	}
	.copy_right {
		padding: 1em 0;
	}
}
 
@media(max-width: 414px){  
	.contact_left_grid {
		width: 100%;
		padding: 0;
	}  
	.contact-top1 {
		padding: 2.5em 1em 2em;
	}
	.left-mk, .right-mk {
		width: 100%;
	} 
}
@media(max-width: 384px){
	.header_right {
		width: 57%;
	}
	ul.agile_forms {
		margin: 3.5em 0 1em;
		text-align: center;
		float: none;
	} 
	.left-mk, .right-mk {
		width: 100%;
	}
	.header_right {
		width: 80%;
	} 
	.about_opa {
		padding: 60px 50px 0;
	}
	.footer_grid_left1.a3 {
		float: none;
		width: 100%;
	}
	.footer_grid_left1.footer_grid_right1 {
		float: none;
		width: 100%;
	}
	.footer_grid_left1.footer_grid_right1{
		margin-top: 2em;
	}
}
@media(max-width: 375px){
	.header-w3l h4 {
		letter-spacing: 1px;
	} 
	.about_opa {
		padding: 50px 50px 0; 
	}
}
@media(max-width: 320px){
	.header_right {
		width: 100%;
	} 
	.header-w3l h4 {
		letter-spacing: 0px;
		font-size: 1.25em;
	}
	.header-w3l h2 {
		font-size: 1.8em;
	} 
	h3.title {
		font-size: 2em;
	}
	.header p:before {
		left: 29%;
	}
	.header p:before, .header p:after {
		width: 12%;
	}
	.header p:after {
		right: 29%;
	}  
	.textbox h4 {
		font-size: 1.3em;
	}
	.textbox p {
		padding: 3px 0;
	}
	.about_img h5 {
		line-height: 1.8;
		height: 50px;
		font-size: 1.7em;
	}
	.about_opa {
		padding: 30px 20px 0;
	}
	.about_img:hover .about_opa {
		top: 50px;
	}
	.contact-grid1 textarea {
		height: 120px;
	} 
	#map {
		height: 250px; 
		width: 100%;
	}
	.copy_right p {
		letter-spacing: 1px;
	}
} 
	.Drawer{
		position:fixed;
		right:0; 
		top: 0;
		width: 95%;
		min-height: 900px;
		background-color: rgb(255, 255, 255);
		z-index: 3322;
		color:white;     
	}   
	/* .facility */ 
	.facility-img {
		height: 100%;
	   width: 100%;
	   display: flex;
	   justify-content: center;
	   align-items: center;
	   margin: 0;
	   padding: 0;
	
	   
 }  

 .facility-img img {
	   height: 100%;
	   width: 50%;
	   padding: 20px;
 } 
 .dept-info {
	   width: 100%;
	   display: flex;
	   justify-content: center;
	   align-items: center;
 } 
 .dept-box {
	   width: 95%;
 } 
 .dept-box,
 .vision,
 .mission,
 .about-dept {
	   display: flex;
	   justify-content: center;
	   align-items: center;
	   flex-direction: column;
 }

 .dept-info h1 {
	   color: white;
	   width: 100%;
	   margin: 40px;
	   display: flex;
	   justify-content: center;
	   align-items: center;
	   flex-direction: column;
	   background-color: blue;
	   padding: 2px;
	   font-family: 'Niconne', cursive;
 }

 .dept-info p {
	   color: black;
	   color: rgb(0, 0, 0);
	   padding: 0px;
	   font-family: 'Niconne', cursive;
	   font-size: 1.6rem;
	   line-height: 30px;
	   font-weight: 5px;
 } 
 #sub-dept,
 #sub-dept a {
	   display: flex;
	   flex-direction: column;
	   justify-content: center;
	   align-items: center;
 } 
 #sub-dept a {
	   width: 86%;
	   height: 40px;
	   background: rgb(53, 53, 223);
	   margin: 10px 10px;
	   color: white;
	   border-radius: 20px;
 } 
 .sub {
	   display: flex;
	   justify-content: center;
	   align-items: center; 
 } 
 .sub h1 {
	   color: blue;
 } 
 .sub .table-responsive {
	   margin-left: 40px;
	   
	   padding: 0;
	   margin-left: 20px;
 } 
 th {
	   color: red;
 }
 .visit{
	   background-color: rgb(0, 102, 255);
	   color: cornsilk;
	   border-radius: 10px;
	   padding: 10px;
	   font-size: 1.6rem;
	   font-family:serif;
 }

 @media screen and (max-width: 500px) {
	   .dept-info h1 {
			 font-size: 20px;
	   } 
	   .sub {
			 flex-direction: column;
	   } 
	   .facility-img  {
		flex-direction: column;
		 
	   } 
	   .facility-img img  {
	   
		width: 98% ;      
		height:100%;
	   } 
	   .dept-box {
			 width: 95%;
	   } 
	   .dept-info h1 {
			 margin: 10px;
	   } 
	   .dept-info p {
			 font-size: 1.3rem;
	   }
	}
 
